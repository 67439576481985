import React from 'react';
import { Box, Flex } from 'rebass';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Image from '../components/Image';
import { H1Text, P1Text } from '../components/Typography/styled';
import colors2 from '../utils/theme/theme2/colors2';
import { breakpoints } from '../utils/theme/theme2/breakpoints';
import { PageWrapper } from '../components/homePage/styled';
import SEO from '../components/SEO';

const styles = {
  container: {
    maxWidth: 810,
    width: '100%',
    marginTop: '20px',
    paddingTop: 60,
  },

  text: {
    color: colors2.black,
    textAlign: 'center',
  },
};
export const ThankYouPageMVPContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  height: 100vh;
  ${H1Text} {
    font-size: 100px;
    line-height: 100px;
    text-align: center;
  }
  ${P1Text} {
    color: ${colors2.lightText};
    text-align: center;
  }
  @media ${breakpoints.sm} {
    gap: 20px;
    ${H1Text} {
      font-size: 50px;
      line-height: 50px;
    }
  }
`;

const ThankYoMVPPage = ({
  data: {
    site: {
      siteMetadata: { titleTemplate, siteUrl },
    },
    pageInfo: {
      frontmatter: { seoTitle, description, keywords },
    },
    thanksImg,
  },
}) => {
  return (
    <PageWrapper>
      <SEO
        titleTemplate={titleTemplate}
        title={seoTitle}
        keywords={keywords}
        url={siteUrl}
        description={description}
      />
      <Header />
      <Flex flexDirection="column" alignItems="center">
        <Flex flexDirection="column" style={styles.container}>
          <Flex flexDirection="column" alignItems="center" justifyContent="center">
            <Flex
              style={{
                flexDirection: 'column',
                maxWidth: '530px',
                width: '100%',
                height: '60vh',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              id="thank-you-container"
            >
              <Image image={thanksImg} alt="image" />
              <Box
                style={{
                  fontWeight: '700',
                  fontSize: '34px',
                  color: '#323347',
                  marginBottom: '20px',
                  marginTop: '50px',
                }}
              >
                Thanks for contacting us!
              </Box>
              <Box style={styles.text}>
                Check your inbox to get your project estimate, which can be easily presented to your
                board. If not received within 15 seconds, check your spam folder.
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Footer />
    </PageWrapper>
  );
};

export const query = graphql`
  {
    site {
      siteMetadata {
        titleTemplate
        siteUrl
      }
    }
    pageInfo: markdownRemark(frontmatter: { name: { eq: "thank-you-mvp" } }) {
      frontmatter {
        title
        longTitle
        seoTitle
        description
        keywords
      }
    }
    thanksImg: file(relativePath: { eq: "images/ThanksForPatience.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, width: 200, height: 200, placeholder: NONE, layout: FIXED)
      }
    }
  }
`;

export default ThankYoMVPPage;
