import React, { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useLocation } from '@reach/router';
import {
  FirsSectionWrapper,
  FormWrapper,
  StyledInput,
  StyledTextarea,
  SubmitBtn,
  StyledPhoneInput,
} from './styled';
import { DropzoneComponent } from '../Dropzone';
import { H1Text, P2Text, P3Text } from '../Typography/styled';
import colors2 from '../../utils/theme/theme2/colors2';
import Select from '../Select';
import { CONTACT_PAGE_FORM_FIELD_NAMES } from '../../constants';
import {
  handleNetlifyContactUsFormSubmit,
  sendContactFormDataToDashapps,
} from '../../utils/sendFormHelper';

const FormSection = () => {
  const options = useMemo(
    () =>
      ['<$20K', '$20K-$50K', '$50K-$100K', '$100K-$200K', '$200K+'].map((item) => ({
        value: item,
        label: item,
      })),
    []
  );

  const [dropzone, setDropzone] = useState([]);
  const [setBudget] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [utmCampaign] = useState(() => {
    const value = queryParams.get('utm_campaign') || '';
    if (value === 'featured-listing-software-developers-eastern-europe') {
      return 'developers eastern europe';
    }

    if (value === 'web-developers') {
      return 'web developers';
    }

    return value ? value.replace(/-/g, ' ') : '';
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = async (data, e) => {
    handleNetlifyContactUsFormSubmit(e, dropzone[0]);
    sendContactFormDataToDashapps({ ...data });
    reset();
  };

  return (
    <FirsSectionWrapper>
      <div>
        <H1Text id="Clutch_ranked">
          Ranked #1 <br />
          Web & Mobile Development Firm By Clutch.co
        </H1Text>
        <P2Text color={colors2.lightText}>
          Empower your business with a custom software solution. Start a project in 10 days, review
          new features every 1–3 weeks, and get a quality product within a budget.
        </P2Text>
      </div>

      <form
        onSubmit={handleSubmit(onSubmit)}
        name="DashbouquetContactUsForm"
        method="post"
        encType="multipart/form-data"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/thankyou"
      >
        <FormWrapper>
          <span>
            <StyledInput
              placeholder="Name"
              {...register(CONTACT_PAGE_FORM_FIELD_NAMES.NAME, {
                required: 'Name is required',
                maxLength: {
                  value: 30,
                  message: 'must be max 30 chars',
                },
                pattern: {
                  value: /[^[\]!@%~?:#$%^&*(){}'\s]/,
                  message: 'Please enter a valid name',
                },
              })}
            />
            {errors[CONTACT_PAGE_FORM_FIELD_NAMES.NAME] && (
              <P3Text color={colors2.red}>
                {errors[CONTACT_PAGE_FORM_FIELD_NAMES?.NAME].message}
              </P3Text>
            )}
          </span>
          <span>
            <StyledInput
              placeholder="Company email"
              {...register(CONTACT_PAGE_FORM_FIELD_NAMES.EMAIL, {
                required: 'Email is required',
                pattern: {
                  value:
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Please enter a valid email',
                },
              })}
            />
            {errors[CONTACT_PAGE_FORM_FIELD_NAMES.EMAIL] && (
              <P3Text color={colors2.red}>
                {errors[CONTACT_PAGE_FORM_FIELD_NAMES.EMAIL]?.message}
              </P3Text>
            )}
          </span>
          <StyledPhoneInput>
            <PhoneInput
              {...register(CONTACT_PAGE_FORM_FIELD_NAMES.PHONE)}
              international
              value={phoneValue}
              onChange={setPhoneValue}
            />
          </StyledPhoneInput>

          <Select
            {...register(CONTACT_PAGE_FORM_FIELD_NAMES.BUDGET)}
            placeholder="Expected budget"
            options={options}
            onChange={setBudget}
          />
          <span className="textarea">
            <StyledTextarea
              placeholder="Tell us more about your idea or project"
              {...register(CONTACT_PAGE_FORM_FIELD_NAMES.MESSAGE, {
                required: 'Message is required',
                pattern: {
                  value: /[^[\]!@%~?:#$%^&*(){}'\s]/,
                  message: 'Please enter a valid message',
                },
              })}
            />
            {errors[CONTACT_PAGE_FORM_FIELD_NAMES.MESSAGE] && (
              <P3Text color={colors2.red}>
                {errors[CONTACT_PAGE_FORM_FIELD_NAMES.MESSAGE]?.message}
              </P3Text>
            )}
          </span>
          <DropzoneComponent handleChange={setDropzone} />
          <SubmitBtn type="submit">Submit</SubmitBtn>
          <P3Text className="subtitle">
            By submitting this form, you agree to give us access to your personal data according to
            our Privacy policy.
          </P3Text>
          <input type="hidden" {...register('bot-field')} />
          <input type="hidden" name="form-name" value="DashbouquetContactUsForm" />

          <input
            {...register('subject')}
            type="hidden"
            value={`Dashbouquet contact us form submission - clutch${
              utmCampaign ? ` (${utmCampaign})` : ''
            }`}
          />
        </FormWrapper>
      </form>
    </FirsSectionWrapper>
  );
};

export default FormSection;
